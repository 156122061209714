// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-about-jsx": () => import("./../../../src/templates/about.jsx" /* webpackChunkName: "component---src-templates-about-jsx" */),
  "component---src-templates-project-jsx": () => import("./../../../src/templates/project.jsx" /* webpackChunkName: "component---src-templates-project-jsx" */),
  "component---src-templates-role-jsx": () => import("./../../../src/templates/role.jsx" /* webpackChunkName: "component---src-templates-role-jsx" */),
  "component---src-templates-single-contact-jsx": () => import("./../../../src/templates/single/contact.jsx" /* webpackChunkName: "component---src-templates-single-contact-jsx" */),
  "component---src-templates-single-downloads-jsx": () => import("./../../../src/templates/single/downloads.jsx" /* webpackChunkName: "component---src-templates-single-downloads-jsx" */),
  "component---src-templates-single-index-jsx": () => import("./../../../src/templates/single/index.jsx" /* webpackChunkName: "component---src-templates-single-index-jsx" */),
  "component---src-templates-single-privacyverklaring-jsx": () => import("./../../../src/templates/single/privacyverklaring.jsx" /* webpackChunkName: "component---src-templates-single-privacyverklaring-jsx" */),
  "component---src-templates-single-projecten-jsx": () => import("./../../../src/templates/single/projecten.jsx" /* webpackChunkName: "component---src-templates-single-projecten-jsx" */),
  "component---src-templates-single-referenties-jsx": () => import("./../../../src/templates/single/referenties.jsx" /* webpackChunkName: "component---src-templates-single-referenties-jsx" */),
  "component---src-templates-single-sample-jsx": () => import("./../../../src/templates/single/sample.jsx" /* webpackChunkName: "component---src-templates-single-sample-jsx" */)
}

